import React, {useEffect} from 'react';
import s from "./ServicePageTemplate.module.css";
import g from "../../globalStyles.module.css";
import {MdOutlineDownloadDone} from "react-icons/md";

function ServicePagesTemplate(props) {
    const url = window.location.pathname.split('/').pop()
    useEffect(() => {
        window.scrollTo({top: 0, left: 0,});
    },[url])

    return (
        <>
            {props.Component && <props.Component />}

            <section className={s.templatePage}>
                <div>
                    {/*<div className={g.sectionTitle}>*/}
                    {/*    <h2>{props.data.title}</h2>*/}
                    {/*</div>*/}
                    <div className={s.templateContent}>
                        <div className={s.imageBlock}>
                            <img src={props.image} alt=""/>
                        </div>
                        <div className={s.InfoBlock}>
                            <div className={s.infoTitle}><h1>{props.data.title}</h1></div>
                            <div className={s.info}>
                                <p>{props.data.description}</p>
                                <p>{props.data.description2}</p>
                                {props.data.description3 && <p>{props.data.description3}</p>}
                            </div>
                            <div className={s.description}>
                                <p>{props.data.offer}</p>
                                {props.data?.items?.map((item,i) => {
                                    return (
                                        <div className={s.item} key={item.item + i}>
                                            <div><MdOutlineDownloadDone /></div>
                                            <div>{item.item}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>

    );
}

export default ServicePagesTemplate;