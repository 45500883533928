import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import React from "react";

export const AboutMeta = () => {
    const {t} = useTranslation()
    return (
        <Helmet>
            <title>{t("menu.company.subMenu.item1")}</title>
            <meta
                name="description"
                content="Մեր ընկերության մասին"
            />
        </Helmet>
    )
}

export const CoursesMeta = () => {
    const {t} = useTranslation()
    return (
        <Helmet>
            <title>{t("trainings.title")}</title>
            <meta
                name="description"
                content="Դասընթացներ ՝ Հաշվապահական հաշվառման բազային գիտելիքների դասընթացներ, ՀԾ հաշվապահ ծրագրի ուսուցում, Microsoft Excel խորացված դասընթացներ"
            />
        </Helmet>
    )
}

export const AccountingMeta = () => {
    const {t} = useTranslation()
    return (
        <Helmet>
            <title>{t("accounting.title")}</title>
            <meta
                name="description"
                content="Հաշվապահական հաշվառման վարում ` Հաշվապահական հաշվառման ներդրում, վերականգնում և վարում, Հարկային հաշվառում և հաշվետվությունների ներկայացում, Պետական մարմիններին հաշվետվությունների պատրաստում, ՖՀՄՍ-ների համապատասխան ֆինանսական հաշվետվությունների պատրաստում"
            />
        </Helmet>
    )
}

export const TaxMeta = () => {
    const {t} = useTranslation()
    return (
        <Helmet>
            <title>{t("tax.title")}</title>
            <meta
                name="description"
                content="Հարկային խորհրդատվություն ` Հարկերի և այլ պարտադիր վճարների հաշվարկում, վճարումների և մնացորդների ստուգում, Հարկային օրենսդրության վերաբերյալ խորհրդատվություն, Հարկային ռիսկերի գնահատում"
            />
        </Helmet>
    )
}

export const PlanningMeta = () => {
    const {t} = useTranslation()
    return (
        <Helmet>
            <title>{t("planning.title")}</title>
            <meta
                name="description"
                content="Բիզնես պլանավորում, Բիզնես պլանավորում և ռազմավարության մշակում, Բիզնես գործունեության արդյունավետության գնահատում"
            />
        </Helmet>
    )
}

export const HrMeta = () => {
    const {t} = useTranslation()
    return (
        <Helmet>
            <title>{t("hr.title")}</title>
            <meta
                name="description"
                content="Կադրային գործ, Աշխատանքային պայմանագրերի կազմում ՀՀ օրենսդրության համապատասխան, Ներքին իրավական ակտերի և կանոնակարգի վարում ու մշակում, Համակարգել Ձեր ընկերության մարդկային ռեսուրսները"
            />
        </Helmet>
    )
}
