import React from 'react';
import Header from "../../components/Header/Header";
import HeartbeatMiniSection from "../../components/HeartbeatMiniSection/HeartbeatMiniSection";
import Services from "../../components/Services/Services";
import UsefulResources from "../../components/UsefulResources/UsefulResources";
import EmailSendMethods from "../../components/EmailSendMethods/EmailSendMethods";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

function Home() {
    const {t} = useTranslation()
    return (
        <>
            <Helmet>
                <title>Tever Group - {t("menu.home.text")}</title>
                <meta
                    name="description"
                    content="Հաշվապահական հաշվառում և ֆինանսներ։Հաշվապահություն, վստահեք Ձեր բիզնեսը մեր պրոֆեսիոնալ թիմին։"
                />
            </Helmet>
            <>
                <Header />
                <Services />
                <HeartbeatMiniSection />
                <UsefulResources />
                <EmailSendMethods />
            </>
        </>

    );
}

export default Home;